import React, { useState } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"
import CardSimple from "./CardSimple"
import Layout from "./layout"
import Seo from "./seo"
import { Button, Typography, Grid, Box } from "@mui/material"
import PasadorImagenesLink from "../components/Imagenes/PasadorImagenesLink"

import Textos from "../components/Textos/Textos"
import Boton from "../components/Botones/Boton"
import BotonImagen from "../components/Botones/BotonImagen"
import Video from "../components/Videos/Video"
import PieDePagina from "./PieDePagina"
import PasadorImagenes from "./Imagenes/PasadorImagenes"
import GaleriaImagenes from "./Imagenes/GaleriaImagenes"
import TextoConLink from "../components/Textos/TextoConLink"
import ReactMarkdown from "react-markdown"

const Pagina = ({ data }) => {
  const { id, nombre, dinamica } = data.pagina.edges[0].node
  const footer = data.footer.footer
  // console.log("data", JSON.stringify(data.pagina.edges[0].node.dinamica))
  // dinamica.map(items => console.log("item", items.__typename)) //nombre del componente

  // const image = getImage(data.strapiPrincipal.imagenFondo.file)
  //   const bgImage = convertToBgImage(image)
  return (
    <Layout>
      <Seo title={nombre} />

      <Grid
        container
        // direction="column"
        // direction="row"
        // spacing={12}
      >
        {dinamica.map(item => (
          <Grid
            container
            item
            xs={item.breakpoints.xs === 0 ? "auto" : item.breakpoints.xs}
            sm={item.breakpoints.sm === 0 ? "auto" : item.breakpoints.sm}
            md={item.breakpoints.md === 0 ? "auto" : item.breakpoints.md}
            lg={item.breakpoints.lg === 0 ? "auto" : item.breakpoints.lg}
            xl={item.breakpoints.xl === 0 ? "auto" : item.breakpoints.xl}
            // direction="row"
            key={`zona-${item.__typename}${item.id}`}
            sx={{
              display: {
                xs: `${item.display.xs}`,
                sm: `${item.display.sm}`,
                md: `${item.display.md}`,
                lg: `${item.display.lg}`,
                xl: `${item.display.xl}`,
              },
            }}
          >
            {console.log("{item.texto_enriquecido}", item.texto_enriquecido)}
            {(() => {
              switch (item.__typename) {
                case "StrapiComponentBotonesBoton":
                  return <Boton item={item} />
                case "StrapiComponentBotonesBotonImagen":
                  return <BotonImagen item={item} />

                case "StrapiComponentVideosIframe":
                  return (
                    <Grid
                      item
                      sx={{
                        width: "100%",
                        position: "relative",
                        height: 900,
                        paddingBottom: "56.25%",
                      }}
                    >
                      <iframe
                        src={item.link}
                        title="Video"
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                      ></iframe>
                    </Grid>
                  )
                case "StrapiComponentTextosTexto":
                  return <Textos item={item} />
                case "StrapiComponentTextosTextoEnriquecido":
                  return (
                    <Grid
                      item
                      sx={{
                        width: "100%",
                        color: `${item.propiedades_texto.color_letra}`,
                        // backgroundColor: `${item.color_fondo}`,
                        textAlign: item.propiedades_texto.alineacion,
                        fontStyle: `${item.propiedades_texto.font_style}`,
                        fontFamily: `${
                          item.propiedades_texto.tipografia === "roboto_slab"
                            ? "Roboto Slab"
                            : item.propiedades_texto.tipografia ===
                              "roboto_mono"
                            ? "Roboto Mono"
                            : item.propiedades_texto.tipografia === "bebas_neue"
                            ? "Bebas Neue"
                            : item.propiedades_texto.tipografia
                        }`,
                        fontWeight: `${item.propiedades_texto.font_weight}`,
                        padding: `${item.propiedades_texto.padding}px`,
                      }}
                    >
                      <ReactMarkdown>{item.texto_enriquecido}</ReactMarkdown>
                    </Grid>
                  )
                case "StrapiComponentUtilesVacio":
                  return (
                    <Grid item sx={{ width: "100%" }}>
                      <p>
                        <br />
                      </p>
                    </Grid>
                  )
                case "StrapiComponentUtilesSeccion":
                  return <div id={item.nombre} />
                case "StrapiComponentVideosVideo":
                  return <Video item={item} />
                case "StrapiComponentTextosImagenTituloTexto":
                  return <CardSimple item={item} />
                case "StrapiComponentImagenesGaleriaImagenes":
                  return <GaleriaImagenes item={item} />
                case "StrapiComponentTextosTextoConLink":
                  return <TextoConLink item={item} />
                case "StrapiComponentImagenesPasadorImagenes":
                  return <PasadorImagenes item={item} />
                case "StrapiComponentImagenesPasadorImagenesLink":
                  return <PasadorImagenesLink item={item} />
                default:
                  return item.__typename
              }
            })()}
          </Grid>
        ))}

        {/* FOOTER */}
        {/* TODO booleano si mostrar en esta pagina o nom ademas de las propiedades de margin etc width */}
        <PieDePagina footer={footer} />
      </Grid>
      <div
        style={{
          position: "fixed",
          right: 10,
          bottom: 10,
          // z-index:1000,
          width: 100,
          height: 100,
          textAlign: "center",
          marginTop: 22,
        }}
        onClick={() => navigate("/como-ayudar")}
      >
        <StaticImage
          style={{ cursor: "pointer" }}
          src="../images/donar.png"
          alt="Donar"
          placeholder="blurred"
          layout="fixed"
          width={100}
          height={100}
        />
      </div>
    </Layout>
  )
}

export default Pagina

export const query = graphql`
  query ($id: String!) {
    footer: strapiPrincipal {
      # titulo
      footer {
        ... on StrapiComponentTextosTextoConLink {
          id
          __typename
          destinoLink
          link
          texto
          # tipo
          propiedades_texto {
            alineacion
            color_letra
            font_style
            font_weight
            padding
            tipografia
          }
          display {
            lg
            md
            sm
            xl
            xs
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
        }
        ... on StrapiComponentRedesLinkedin {
          __typename
          id
          propiedades_iconos {
            color
            color_fondo
            padding
          }
          link
          imagen {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }

        ... on StrapiComponentRedesEmail {
          __typename
          id
          propiedades_iconos {
            color
            color_fondo
            padding
          }
          link
          imagen {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentRedesTelefono {
          __typename
          id
          propiedades_iconos {
            color
            color_fondo
            padding
          }
          link
          imagen {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentRedesWhatsapp {
          __typename
          id
          propiedades_iconos {
            color
            color_fondo
            padding
          }
          link
          imagen {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentRedesTwitter {
          __typename
          id
          propiedades_iconos {
            color
            color_fondo
            padding
          }
          link
          imagen {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentRedesInstagram {
          __typename
          id
          propiedades_iconos {
            color
            color_fondo
            padding
          }
          link
          imagen {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentRedesFacebook {
          __typename
          id
          propiedades_iconos {
            color
            color_fondo
            padding
          }
          link
          imagen {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentUtilesPropiedadesFooter {
          __typename
          id
          color_fondo
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentUtilesVacio {
          __typename
          id
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
        ... on StrapiComponentImagenesPasadorImagenes {
          id
          __typename
          padding
          temporizador
          imagenes {
            alternativeText
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
                id
              }
            }
          }
          display {
            md
            lg
            sm
            xl
            xs
          }
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
        }
        ... on StrapiComponentBotonesBoton {
          __typename
          id
          # size
          destino
          link
          breakpoints {
            lg
            md
            sm
            xl
            xs
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
          color_fondo
          texto
          propiedades_texto {
            alineacion
            color_letra
            font_style
            font_weight
            padding
            tipografia
          }
        }
        ... on StrapiComponentTextosTexto {
          __typename
          id
          texto
          tipo
          color_fondo
          gutter_bottom
          propiedades_texto {
            font_style
            font_weight
            color_letra
            alineacion
            tipografia
            padding
          }
          breakpoints {
            xs
            sm
            md
            lg
            xl
          }
          display {
            xs
            sm
            md
            lg
            xl
          }
        }
      }
    }
    # allStrapiPagina(filter: {id: {eq: "id"}}) {
    pagina: allStrapiPagina(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          nombre
          dinamica {
            ... on StrapiComponentTextosTextoEnriquecido {
              __typename
              id
              texto_enriquecido
              propiedades_texto {
                alineacion
                color_letra
                font_style
                font_weight
                padding
                tipografia
              }
              display {
                lg
                md
                sm
                xl
                xs
              }
              breakpoints {
                lg
                md
                sm
                xl
                xs
              }
            }
            ... on StrapiComponentBotonesBotonImagen {
              __typename
              id
              destinoDelLink
              link
              imagen {
                file {
                  childImageSharp {
                    gatsbyImageData(width: 600)
                  }
                }
              }
              propiedades_texto {
                alineacion
                color_letra
                font_style
                font_weight
                padding
                tipografia
              }
              display {
                lg
                md
                sm
                xl
                xs
              }
              breakpoints {
                lg
                md
                sm
                xl
                xs
              }
            }
            ... on StrapiComponentVideosIframe {
              __typename
              link
              id
              display {
                lg
                md
                sm
                xl
                xs
              }
              breakpoints {
                lg
                md
                sm
                xl
                xs
              }
            }
            ... on StrapiComponentImagenesPasadorImagenesLink {
              links {
                link
              }
              id
              __typename
              padding
              temporizador
              imagenes {
                file {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1920
                      # placeholder: BLURRED
                      # formats: [AUTO, WEBP, AVIF]
                    )
                    id
                  }
                }
                alternativeText
              }
              display {
                md
                lg
                sm
                xl
                xs
              }
              breakpoints {
                lg
                md
                sm
                xl
                xs
              }
            }
            ... on StrapiComponentUtilesSeccion {
              __typename
              id
              nombre
              display {
                lg
                md
                sm
                xl
                xs
              }
              breakpoints {
                lg
                md
                sm
                xl
                xs
              }
            }
            ... on StrapiComponentTextosTextoConLink {
              id
              __typename
              destinoLink
              link
              texto
              # tipo
              propiedades_texto {
                alineacion
                color_letra
                font_style
                font_weight
                padding
                tipografia
              }
              display {
                lg
                md
                sm
                xl
                xs
              }
              breakpoints {
                lg
                md
                sm
                xl
                xs
              }
            }
            ... on StrapiComponentUtilesVacio {
              __typename
              id
              breakpoints {
                lg
                md
                sm
                xl
                xs
              }
              display {
                xs
                sm
                md
                lg
                xl
              }
            }

            ... on StrapiComponentImagenesGaleriaImagenes {
              __typename
              padding_imagen
              padding
              ampliar
              breakpoints {
                lg
                md
                sm
                xl
                xs
              }
              imagenes {
                alternativeText
                file {
                  childImageSharp {
                    gatsbyImageData(width: 1920)
                  }
                  id
                }
              }
              display {
                lg
                md
                sm
                xl
                xs
              }
              breakpoints_imagen {
                lg
                md
                sm
                xl
                xs
              }
              id
            }
            ... on StrapiComponentImagenesPasadorImagenes {
              id
              __typename
              padding
              temporizador
              imagenes {
                alternativeText
                file {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1920
                      # placeholder: BLURRED
                      # formats: [AUTO, WEBP, AVIF]
                    )
                    id
                  }
                }
              }
              display {
                md
                lg
                sm
                xl
                xs
              }
              breakpoints {
                lg
                md
                sm
                xl
                xs
              }
            }

            ... on StrapiComponentVideosVideo {
              __typename
              id
              breakpoints {
                lg
                md
                sm
                xl
                xs
              }
              display {
                xs
                sm
                md
                lg
                xl
              }
              video {
                alternativeText
                mime
                file {
                  publicURL
                }
              }
            }

            ... on StrapiComponentBotonesBoton {
              __typename
              id
              # size
              destino
              link
              breakpoints {
                lg
                md
                sm
                xl
                xs
              }
              display {
                xs
                sm
                md
                lg
                xl
              }
              color_fondo
              texto
              propiedades_texto {
                alineacion
                color_letra
                font_style
                font_weight
                padding
                tipografia
              }
            }

            ... on StrapiComponentTextosTexto {
              __typename
              id
              texto
              tipo
              color_fondo
              gutter_bottom
              propiedades_texto {
                font_style
                font_weight
                color_letra
                alineacion
                tipografia
                padding
              }
              breakpoints {
                xs
                sm
                md
                lg
                xl
              }
              display {
                xs
                sm
                md
                lg
                xl
              }
            }
            ... on StrapiComponentTextosImagenTituloTexto {
              __typename
              id
              texto
              titulo
              padding
              # tipo
              # color_fondo
              # gutter_bottom
              imagen {
                alternativeText
                file {
                  childImageSharp {
                    gatsbyImageData(width: 1920)
                  }
                  id
                }
              }
              propiedades_texto {
                font_style
                font_weight
                color_letra
                alineacion
                tipografia
                padding
              }
              propiedades_titulo {
                font_style
                font_weight
                color_letra
                alineacion
                tipografia
                padding
              }
              breakpoints {
                xs
                sm
                md
                lg
                xl
              }
              display {
                xs
                sm
                md
                lg
                xl
              }
            }
          }
        }
      }
    }
  }
`
